import React from 'react';
import Link from 'next/link';
// import ReCAPTCHA from 'react-google-recaptcha';

import { useDispatch, useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';

import { register, login } from '../redux/user/actions';
import ControlledInput from '../components/inputs/ControlledInput';
import Button from '../components/buttons/Button';
import Layout from '../layout/Layout';

const Login = () => {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  const { control: controlRegister, handleSubmit: handleSubmitRegister } =
    useForm();

  const dispatch = useDispatch();

  const validate = (data) => {
    if (!data.email) {
      setError('email', {
        type: 'required',
        message: 'Debe introducir un email válido',
      });
    }
    if (!data.password) {
      setError('password', {
        type: 'required',
        message: 'Debe introducir una contraseña',
      });
    }
    if (data.password && data.password.length < 6) {
      setError('password', {
        type: 'maxLength',
        message: 'La contraseña debe contener almenos 6 carácteres',
      });
    } else {
      return true;
    }
  };

  // const recaptchaRef = useRef();
  // const recaptchaRefRegister = useRef();

  const onSubmitLogin = async (data) => {
    if (validate(data)) {
      // const token = await recaptchaRef.current.executeAsync();
      // if (token) {
      dispatch(login(data));
      // }
    }
  };

  const onSubmitRegister = async (data) => {
    if (validate(data)) {
      // const token = await recaptchaRefRegister.current.executeAsync();
      // if (token) {
      dispatch(register(data));
      // }
    }
  };

  const isLoadingRegister = useSelector(
    (state) => state.user.isLoadingRegister
  );
  const isLoadingLogin = useSelector((state) => state.user.isLoadingLogin);

  return (
    <Layout
      link="acceder"
      className="c-body--login"
      classNameBodyContainer="c-login__container">
      <form onSubmit={handleSubmit(onSubmitLogin)} className="c-login">
        {/* <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY}
        /> */}
        <h2 className="c-register__title">ACCESO MIEMBROS</h2>
        <img
          className="c-register__logo"
          src="/img/logo-els-ports.svg"
          alt="agenda els ports es autèntic"
        />
        <p className="c-register__description">
          ¿Eres organizador de un evento en Els Ports?
        </p>
        <div className="c-login__inputs-container">
          <ControlledInput
            variant="outlined"
            color="brown-5"
            control={control}
            error={errors.email}
            name="email"
            label="Email"
            type="email"
          />
          <ControlledInput
            variant="outlined"
            color="brown-5"
            control={control}
            error={errors.password}
            name="password"
            label="Contraseña"
            type="password"
          />
        </div>
        <Link href="/forgot-password">
          <a className="c-login__restore-password">
            ¿Has olvidado tu contraseña?
          </a>
        </Link>

        <Button
          type="submit"
          className="c-login__button"
          isLoading={isLoadingLogin}>
          Iniciar Sesión
        </Button>
      </form>
      <form
        onSubmit={handleSubmitRegister(onSubmitRegister)}
        className="c-register">
        {/* <ReCAPTCHA
          ref={recaptchaRefRegister}
          size="invisible"
          sitekey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY}
        /> */}
        <h2 className="c-register__title">NUEVO MIEMBRO</h2>
        <p className="c-register__description">
          Espacio dedicado a empresas, emprendedores y entidades que deseen
          anunciar y publicar eventos y acontecimientos en la comarca de Els
          Ports.
        </p>
        <div className="c-register__inputs-container">
          <ControlledInput
            variant="outlined"
            control={controlRegister}
            name="username"
            label="Nombre del organizador"
          />
          <ControlledInput
            variant="outlined"
            control={controlRegister}
            name="email"
            label="Email"
            type="email"
          />
          <ControlledInput
            variant="outlined"
            control={controlRegister}
            name="password"
            label="Contraseña"
            type="password"
          />
        </div>
        <p className="c-register__terms">
          Enviando este formulario de registro estás aceptando nuestra{' '}
          <Link href="/terms-cookies">
            <a className="link">política de privacidad</a>
          </Link>{' '}
          y das consentimiento para que contactemos contigo. Si tienes dudas
          puedes consultar nuestra{' '}
          <Link href="/ayuda">
            <a className="link">sección de ayuda</a>
          </Link>{' '}
        </p>

        <Button
          type="submit"
          isLoading={isLoadingRegister}
          className="c-register__button">
          Registrarse
        </Button>
      </form>
    </Layout>
  );
};

export default Login;
